import { createApp } from 'vue';
//store
import { createPinia, type StateTree } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';
//languages
import { createI18n } from 'vue-i18n';
import { languages } from '@/languages';
//router
import App from './App.vue';
import router from './router';
//vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import '@mdi/font/css/materialdesignicons.css';
//qr code generator
import VueQrcode from '@chenfengyuan/vue-qrcode';
//scrollbars
import OverlayScrollbars from '@codeporter/overlayscrollbars-vue';
import 'overlayscrollbars/css/OverlayScrollbars.css';
//sentry
import * as Sentry from '@sentry/vue';

// VueDragSelect
import VueDragSelect from "@coleqiu/vue-drag-select";

// date-fns
import { cs as dateFnsCs } from 'date-fns/locale'
import { setDefaultOptions } from 'date-fns';

import './assets/css/main.scss';
import * as yup from 'yup';
import yupLocale from '@/plugins/yup/locale';
import vueSimpleAcl from '@/plugins/vue-simple-acl';
import vContentEditable from '@/directives/vContentEditable';
import { cs, en } from 'vuetify/locale';

const SERIALIZER_CACHE_DURATION = 1000 * 60 * 60 * 24 * 6; // 6 days

export const i18n = createI18n({
    locale: 'cs',
    fallbackLocale: 'cs',
    legacy: false,
    messages: languages,
});

yup.setLocale(yupLocale(i18n.global.t));

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
    locale: {
        locale: 'cs',
        fallback: 'en',
        messages: { cs, en },
    },
});

// date fns locale
setDefaultOptions({ locale: dateFnsCs })

const app = createApp(App);

if (import.meta.env.VITE_APP_SENTRY_INIT === 'true' && import.meta.env.VITE_APP_SENTRY_DSN) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_APP_SENTRY_DSN,
        environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['localhost:5173', /^http:\/\/193\.85\.18\.64:35173/],
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
    });
}

const pinia = createPinia();
pinia.use(createPersistedState({
    key: id => `__persisted__${id}`,
    serializer: {
        deserialize: (value: string) => {
            const parsed = JSON.parse(value);
            if (parsed.expires_at < Date.now()) {
                return {};
            }
            return parsed.value;
        },
        serialize: (value: StateTree) => {
            return JSON.stringify({
                expires_at: Date.now() + SERIALIZER_CACHE_DURATION,
                value,
            });
        }
    }

}))
app.use(pinia);
app.use(router);
app.use(i18n);
app.use(vuetify);
app.use(OverlayScrollbars);
app.use(vueSimpleAcl);
app.use(VueDragSelect);
app.directive('content-editable', vContentEditable);

app.component(VueQrcode.name, VueQrcode);
app.mount('#app');
