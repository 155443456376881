export enum ModulesEnum {
    AccountingCategories = 'accounting-categories',
    CarBasicMalfunctions = 'car-basic-malfunctions',
    CarCategories = 'car-categories',
    CarRecurrentMalfunctions = 'car-recurrent-malfunctions',
    CarServices = 'car-services',
    CarServicesContact = 'car-service-contact-people',
    Cars = 'cars',
    Clients = 'clients',
    Companies = 'companies',
    Employees = 'employees',
    Inquiries = 'inquiries',
    InquiryCalculationDuties = 'inquiry-calculation-duties',
    InquiryProposalPaymentTerms = 'inquiry-proposal-payment-terms',
    InquiryRegistrationsNumberSeries = 'inquiry-registration-number-series',
    InquiryResources = 'inquiry-resources',
    JobPositions = 'job-positions',
    Licences ='licences',
    Logs = 'logs',
    Permissions ='permissions',
    Producers = 'producers',
    ProjectStatuses = 'project-statuses',
    Projects = 'projects',
    Settings = 'settings',
    StockItemCategories = 'stock-item-categories',
    StockItemLocations = 'stock-item-locations',
    StockItemRegistrationNumberSeries = 'stock-item-registration-number-series',
    StockItemStocktakingNumberSeries = 'stock-item-stocktaking-number-series',
    StockItemSuppliers = 'stock-item-suppliers',
    StockItems = 'stock-items',
    TaxRates = 'tax-rates',
    TermsAndConditions = 'terms-and-conditions',
    Units = 'units',
    Users = 'users',
    Documents = 'quality-management-system-documents',
    FileGroups = 'file-groups',
    TaskGroups = 'user-task-groups',
    ProjectTasks = 'user-tasks-project',
    GeneralTasks = 'user-tasks-general',
    EmployeesOverview = 'employee-reports',
    ManagementReportInvoices = 'management-report-invoices',
}
