import axios from 'axios';
import useUserSession from '@/composables/useUserSession';

const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_APP_API_URL,
});

const { getAuthorizationHeader } = useUserSession();

export const useAxios = () => {
    return axios.create({
        baseURL: import.meta.env.VITE_APP_API_URL,
        headers: getAuthorizationHeader(),
    });
};

export default axiosInstance;
