import { RouteNamesEnum } from '@/enums/RouteNamesEnum';
import { getGenericModulePermissionsList } from '@/composables/usePermissions';
import { ModulesEnum } from '@/enums/ModulesEnum';

const HomeRoutes = [
    {
        path: '/home',
        component: () => import('../components/general/RootModule.vue'),
        meta: {
            title: 'HomeView.title',
            icon: 'mdi-pin',
            auth: {
                required: true,
            },
            showInMenu: true,
            moduleRoot: true,
        },
        children: [
            {
                path: '',
                name: RouteNamesEnum.Home,
                component: () => import('../views/general/HomeView.vue'),
                meta: {
                    title: 'HomeView.title',
                    auth: {
                        required: true,
                        roles: [],
                    },
                    showInMenu: true,
                },
            },
            {
                path: 'board',
                name: RouteNamesEnum.Board,
                component: () => import('../views/general/BoardView.vue'),
                meta: {
                    title: 'BoardModule.title',
                    icon: 'mdi-bulletin-board',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Projects),
                    showInMenu: true,
                },
            },
            {
                path: 'project-tasks',
                name: RouteNamesEnum.ProjectTasks,
                component: () => import('../views/tasks-module/TasksView.vue'),
                meta: {
                    title: 'Tasks.projects-title',
                    auth: {
                        required: true,
                        roles: [],
                    },
                    showInMenu: true,
                    storeMeta: {
                        projectsOnly: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.ProjectTasks),
                },
            },
            {
                path: 'general-tasks',
                name: RouteNamesEnum.GeneralTasks,
                component: () => import('../views/tasks-module/TasksView.vue'),
                meta: {
                    title: 'Tasks.general-title',
                    auth: {
                        required: true,
                        roles: [],
                    },
                    showInMenu: true,
                    storeMeta: {
                        generalOnly: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.GeneralTasks),
                },
            },
            {
                path: 'cost-revenue-reports-view',
                name: RouteNamesEnum.CostRevenueReports,
                component: () => import('../views/management-reports-module/InvoiceReportsView.vue'),
                meta: {
                    title: 'Reports.title',
                    auth: {
                        required: true,
                        roles: [],
                    },
                    showInMenu: true,
                    canAny: getGenericModulePermissionsList(ModulesEnum.ManagementReportInvoices),
                },
            },
            {
                path: '',
                component: () => import('../components/general/SubModule.vue'),
                meta: {
                    title: 'SettingsModule.name',
                    auth: {
                        required: true,
                    },
                    showInMenu: true,
                    canAny: getGenericModulePermissionsList(ModulesEnum.TaskGroups),
                },
                children: [
                    {
                        path: 'task-categories/:id(\\d+|new)?',
                        name: RouteNamesEnum.TaskGroups,
                        component: () =>
                            import('../views/tasks-module/GroupsView.vue'),
                        meta: {
                            title: 'Tasks.categories',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.TaskGroups),
                            showInMenu: true,
                        },
                    },
                ],
            },
        ],
    },
];

export default HomeRoutes;
