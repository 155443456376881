import { RouteNamesEnum } from '@/enums/RouteNamesEnum';

const AuthRoutes = [
    {
        name: RouteNamesEnum.Register,
        path: '/register',
        component: () => import('@/views/general/CreateAccount.vue'),
        meta: {
            title: 'CreateAccountView.title',
            auth: {
                required: false,
                roles: [],
            },
            showInMenu: false,
        },
    },
    {
        name: RouteNamesEnum.Login,
        path: '/',
        component: () => import('@/views/general/LoginView.vue'),
        meta: {
            title: 'LoginView.title',
            auth: {
                required: false,
                roles: [],
            },
            showInMenu: false,
        },
    },
    {
        path: '/reset-password/:token',
        name: RouteNamesEnum.ResetPassword,
        component: () => import('../views/general/ResetPasswordView.vue'),
        meta: {
            title: 'ResetPassword.title',
            auth: {
                required: false,
                roles: [],
            },
            showInMenu: false,
        },
    },
];

export default AuthRoutes;
