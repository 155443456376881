export enum RouteNamesEnum {
    Login = 'login',
    Home = 'home',
    ProjectTasks = 'project-tasks',
    GeneralTasks = 'general-tasks',
    TaskGroups = 'user-task-groups',
    Register = 'register',
    ResetPassword = 'reset-password',
    Gdpr = 'gdpr',
    Users = 'users',
    RolesAndPermissions = 'roles-permissions',
    Cars = 'cars',
    CarsArchive = 'cars-archive',
    CarsCategories = 'cars-categories',
    CarServices = 'car-services',
    CarModuleFiles = 'car-module-files',
    CarBasicMalfunctions = 'car-basic-malfunctions',
    CarRecurrentMalfunctions = 'car-recurrent-malfunctions',
    Settings = 'settings',
    StockItemRegistrationNumberSeries = 'stock-item-registration-number-series',
    StockItemStocktakingRegistrationNumberSeries = 'stock-item-stocktaking-registration-number-series',
    FileGrups = 'file-groups',
    Others = 'others',
    Stock = 'stock',
    StockItemArchive = 'stock-item-archive',
    Stocktaking = 'stocktaking',
    BorrowingStocktaing = 'borrowing-stocktaking',
    StockCategories = 'stock-item-categories',
    Producers = 'producers',
    Locations = 'stock-item-locations',
    Units = 'units',
    AccountingCategories = 'accounting-categories',
    TaxRates = 'tax-rates',
    Suppliers = 'stock-item-suppliers',
    TermsAndContidions = 'terms-and-conditions',
    Employees = 'employees',
    JobPositions = 'job-positions',
    Companies = 'companies',
    Licences = 'licences',
    EmployeesModuleFiles = 'employees-module-files',
    EmployeesOverview = 'employees-overview',
    Reserves = 'reserves',
    EmployeesArchive = 'employees-archive',
    EmployeeApplicants = 'employee-applicants',
    InquiryRegistrationNumberSeries = 'inquiry-registration-number-series',
    InquiryResourceGroups = 'inquiry-resource-groups',
    Clients = 'clients',
    InquiryCalculationDuties = 'calculation-duties',
    InquiryKiloResources = 'kilo-resources',
    InquiryResources = 'resources',
    Inquiries = 'inquiries',
    InquiriesArchive = 'inquiries-archive',
    InquiryCalculations = 'inquiry-calculations',
    InquiryProposals = 'inquiry-proposals',
    MyAccount = 'my-account',
    Projects = 'projects',
    ProjectsArchive = 'projects-archive',
    ProjectCategories = 'project-categories',
    ProjectStockRequirementsListView = 'project-stock-requirements-list',
    DocumentsForms = 'documents-forms',
    DocumentsIso9001Documentation = 'documents-iso9001-documentation',
    DocumentsIso9001Certification = 'documents-iso9001-certification',
    DocumentsIso9001Audits = 'documents-iso9001-audits',
    DocumentsIso9001Certificates = 'documents-iso9001-certificates',
    DocumentsSccDocumentation = 'documents-scc-documentation',
    DocumentsSccCertification = 'documents-scc-certification',
    DocumentsSccAudits = 'documents-scc-audits',
    DocumentsSccCertificates = 'documents-scc-certificates',
    DocumentsIso3834Documentation = 'documents-iso3834-documentation',
    DocumentsIso3834Certification = 'documents-iso3834-certification',
    DocumentsIso3834Audits = 'documents-iso3834-audits',
    DocumentsIso3834Certificates = 'documents-iso3834-certificates',
    Board = 'board',
    LongTermPlan = 'long-term-plan',
    CostRevenueReports = 'cost-revenue-reports-view',
}
