<template>
    <div class="wrapper">
        <v-progress-circular :size="70" :width="7" color="#0E567E" indeterminate/>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    export default defineComponent({
        name: "LoadingComponent",
        components: {
        },
        data() {
            return {
                loading: false as boolean
            };
        }
    });
</script>

<style scoped lang="scss">
    @import '@/assets/css/main.scss';
    .wrapper{
        @include display-flex(column, nowrap, center, center);
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.25);
        z-index: 2500;
    }
</style>
<style lang="scss">
    .v-progress-circular__underlay{
        color: rgba(255, 255, 255, 0.25)!important;
    }
    .v-progress-circular--indeterminate .v-progress-circular__overlay{
        stroke-linecap: initial!important;
    }
    .v-progress-circular--indeterminate .v-progress-circular__overlay,
    .v-progress-circular--indeterminate > svg{
        animation-duration: 3000ms!important;
    }
</style>
