import { RouteNamesEnum } from '@/enums/RouteNamesEnum';
import { ModulesEnum } from '@/enums/ModulesEnum';
import { getGenericModulePermissionsList } from '@/composables/usePermissions';
import { DocumentFilesModuleTypesEnum, DocumentModuleModulesEnum } from '@/stores/documents-module/documentEnumsStore';

const DocumentsRoutes = [
    {
        path: '/documents',
        component: () => import('../components/general/RootModule.vue'),
        meta: {
            title: 'DocumentsModule.name',
            icon: 'mdi-file-cog-outline',
            auth: {
                required: true,
            },
            showInMenu: true,
            moduleRoot: true,
        },
        children: [
            {
                path: 'forms/:id(\\d+|new)?',
                name: RouteNamesEnum.DocumentsForms,
                component: () => import('../views/documents-module/DocumentsView.vue'),
                meta: {
                    title: 'DocumentsModule.forms',
                    auth: {
                        required: true,
                    },
                    canAny: getGenericModulePermissionsList(ModulesEnum.Documents),
                    showInMenu: true,
                    documentTypeValue: DocumentModuleModulesEnum.FORMS,
                }
            },
            {
                path: '',
                component: () => import('../components/general/SubModule.vue'),
                meta: {
                    title: 'DocumentsModule.iso9001',
                    auth: {
                        required: true,
                    },
                    showInMenu: true,
                },
                children: [
                    {
                        path: 'iso9001/documentation/:id(\\d+|new)?',
                        name: RouteNamesEnum.DocumentsIso9001Documentation,
                        component: () => import('../views/documents-module/DocumentsView.vue'),
                        meta: {
                            title: 'DocumentsModule.iso9001-documentation',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Documents),
                            showInMenu: true,
                            documentTypeValue: DocumentModuleModulesEnum.ISO9001_DOCUMENTATION,
                        }
                    },
                    {
                        path: 'iso9001/certification/:id(\\d+|new)?',
                        name: RouteNamesEnum.DocumentsIso9001Certification,
                        component: () => import('../views/documents-module/DocumentsView.vue'),
                        meta: {
                            title: 'DocumentsModule.iso9001-certification',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Documents),
                            showInMenu: true,
                            documentTypeValue: DocumentModuleModulesEnum.ISO9001_CERTIFICATION,
                        }
                    },
                    {
                        path: 'iso9001/audits/:id(\\d+|new)?',
                        name: RouteNamesEnum.DocumentsIso9001Audits,
                        component: () => import('../views/documents-module/DocumentsView.vue'),
                        meta: {
                            title: 'DocumentsModule.iso9001-audits',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Documents),
                            showInMenu: true,
                            documentTypeValue: DocumentModuleModulesEnum.ISO9001_AUDIT,
                        }
                    },
                    {
                        path: 'iso9001/certificates/:id(\\d+|new)?',
                        name: RouteNamesEnum.DocumentsIso9001Certificates,
                        component: () => import('../views/documents-module/DocumentFilesView.vue'),
                        meta: {
                            title: 'DocumentsModule.iso9001-certificates',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Documents),
                            showInMenu: true,
                            documentFileTypeValue: DocumentFilesModuleTypesEnum.ISO9001_CERTIFICATE,
                        }
                    },
                ],
            },
            {
                path: '',
                component: () => import('../components/general/SubModule.vue'),
                meta: {
                    title: 'DocumentsModule.scc',
                    auth: {
                        required: true,
                    },
                    showInMenu: true,
                },
                children: [
                    {
                        path: 'scc/documentation/:id(\\d+|new)?',
                        name: RouteNamesEnum.DocumentsSccDocumentation,
                        component: () => import('../views/documents-module/DocumentsView.vue'),
                        meta: {
                            title: 'DocumentsModule.scc-documentation',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Documents),
                            showInMenu: true,
                            documentTypeValue: DocumentModuleModulesEnum.SCC_DOCUMENTATION,
                        }
                    },
                    {
                        path: 'scc/certification/:id(\\d+|new)?',
                        name: RouteNamesEnum.DocumentsSccCertification,
                        component: () => import('../views/documents-module/DocumentsView.vue'),
                        meta: {
                            title: 'DocumentsModule.scc-certification',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Documents),
                            showInMenu: true,
                            documentTypeValue: DocumentModuleModulesEnum.SCC_CERTIFICATION,
                        }
                    },
                    {
                        path: 'scc/audits/:id(\\d+|new)?',
                        name: RouteNamesEnum.DocumentsSccAudits,
                        component: () => import('../views/documents-module/DocumentsView.vue'),
                        meta: {
                            title: 'DocumentsModule.scc-audits',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Documents),
                            showInMenu: true,
                            documentTypeValue: DocumentModuleModulesEnum.SCC_AUDIT,
                        }
                    },
                    {
                        path: 'scc/certificates/:id(\\d+|new)?',
                        name: RouteNamesEnum.DocumentsSccCertificates,
                        component: () => import('../views/documents-module/DocumentFilesView.vue'),
                        meta: {
                            title: 'DocumentsModule.scc-certificates',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Documents),
                            showInMenu: true,
                            documentFileTypeValue: DocumentFilesModuleTypesEnum.SCC_CERTIFICATE,
                        }
                    },
                ],
            },
            {
                path: '',
                component: () => import('../components/general/SubModule.vue'),
                meta: {
                    title: 'DocumentsModule.iso3834',
                    auth: {
                        required: true,
                    },
                    showInMenu: true,
                },
                children: [
                    {
                        path: 'iso3834/documentation/:id(\\d+|new)?',
                        name: RouteNamesEnum.DocumentsIso3834Documentation,
                        component: () => import('../views/documents-module/DocumentsView.vue'),
                        meta: {
                            title: 'DocumentsModule.iso3834-documentation',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Documents),
                            showInMenu: true,
                            documentTypeValue: DocumentModuleModulesEnum.ISO3834_DOCUMENTATION,
                        }
                    },
                    {
                        path: 'iso3834/certification/:id(\\d+|new)?',
                        name: RouteNamesEnum.DocumentsIso3834Certification,
                        component: () => import('../views/documents-module/DocumentsView.vue'),
                        meta: {
                            title: 'DocumentsModule.iso3834-certification',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Documents),
                            showInMenu: true,
                            documentTypeValue: DocumentModuleModulesEnum.ISO3834_CERTIFICATION,
                        }
                    },
                    {
                        path: 'iso3834/audits/:id(\\d+|new)?',
                        name: RouteNamesEnum.DocumentsIso3834Audits,
                        component: () => import('../views/documents-module/DocumentsView.vue'),
                        meta: {
                            title: 'DocumentsModule.iso3834-audits',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Documents),
                            showInMenu: true,
                            documentTypeValue: DocumentModuleModulesEnum.ISO3834_AUDIT,
                        }
                    },
                    {
                        path: 'iso3834/certificates/:id(\\d+|new)?',
                        name: RouteNamesEnum.DocumentsIso3834Certificates,
                        component: () => import('../views/documents-module/DocumentFilesView.vue'),
                        meta: {
                            title: 'DocumentsModule.iso3834-certificates',
                            auth: {
                                required: true,
                            },
                            canAny: getGenericModulePermissionsList(ModulesEnum.Documents),
                            showInMenu: true,
                            documentFileTypeValue: DocumentFilesModuleTypesEnum.ISO3834_CERTIFICATE,
                        }
                    },
                ],
            },
        ],
    },
];

export default DocumentsRoutes;
